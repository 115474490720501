/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Quick';
  src: url('fonts/Quick-Kiss-Personal-Use.ttf');
}
.list_four_col li {
  float: left;
  width: 25%;
}
.react-multiple-carousel__arrow {
  background: #0b348c !important;
  border-radius: 6px !important;
  min-width: 38px !important;
  min-height: 38px !important;
  color: #fff !important;
}

.react-multiple-carousel__arrow--left {
  left: 0px !important;
  top: 31% !important;
  z-index: 9;
}

.react-multiple-carousel__arrow--right {
  right: 0px !important;
  top: 31% !important;
  z-index: 9;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
top_banner_section button.react-multiple-carousel__arrow {}

section.top_banner_section .react-multiple-carousel__arrow {
    background: rgb(0,0,0,0.3) !important;
    width: 100px;
    height: 100px;
    border-radius: 0px !important;
}

section.top_banner_section .react-multiple-carousel__arrow:hover {
    background: #0b348c !important;
}

section.top_banner_section .react-multiple-carousel__arrow::before {
    font-size: 42px;
}

.dropdown:hover .dropdown-menu {
  display: block;
  z-index:99;
}
.dropdown-menu li {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.dropdown-menu li:last-child {
  margin-bottom: 0px;
}

.dropdown li {
  float: left;
  width: 20%;
  /* width: 33%; */
  /* min-height: 50px; */
}

.dropdown li a {
  background: #f38003;
  display: block;
  padding: 6px;
  border-bottom: solid 1px #fff;
  min-height: 41px;
  transition: all 0.5s linear;
  font-size: 12px;
  border-left: solid 1px #fff;
}

.dropdown li a:hover {
  background: #0b348c;
}

@media only screen and (max-width: 568px) {
  ul.brd_logos li {
      float: left;
      width: 46%;
      margin: 0px 5px 10px;
  }
  .list_four_col li {
    width: 100%;
  }
}

.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.pro_img {
  border: 1px solid #c5c5c5;
  text-align: center;
}
.cartMsg {
  padding: 15px;
  background: #f38003;
  color: #fff;
  width: 50%;
  border-radius: 5px;
}
.cartMsg a {
  float: right;
  border-bottom: 1px solid;
  font-weight: bold;
}

ul.orderList li {
  width: 100%;
  padding: 16px;
  background: #eee;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

ul.orderList li span.orderType {
  float: right;
}

ul.orderList li:hover {
  background: #cfcfcf;
}

ul.orderList li span.createdAt {
  float: right;
  font-size: 12px;
  position: absolute;
  bottom: 3px;
  right: 15px;
}
.boxCon {
  padding: 13px;
  background: #fff;
  margin-left: 10px;
}

.boxCon h3 {
  font-weight: bold;
}

ul.customerDetails li span,ul.orderDetails li span {
  font-weight: 500;
}
.boxCon {
  padding: 13px;
  background: #fff;
  margin-left: 10px;
}

.boxCon h3 {
  font-weight: bold;
}
ul.customerDetails li span,ul.orderDetails li span {
  font-weight: 500;
}
.productBox {
  width: 100%;
  padding: 13px;
  background: #fff;
  margin-left: 10px;
  margin-top: 10px;
}
.productBox h3 {
  font-weight: bold;
}
ul.productDetails li {
  padding: 12px;
  border-bottom: 1px solid #eee;
}
ul.productDetails li img {
  width: 150px;
}
ul.relatedProductsContainer li {
  width: 200px;
  margin-right: 10px;
}

ul.relatedProductsContainer {
  display: flex;
}
.productWidget {
  text-align: center;
}

.productWidget img {
  margin: auto;
  height: 195px;
  object-fit: cover;
}

.productWidget h3 {
  margin-bottom: 0;
}
.cat_single_block {
  width: 32%;
  float: left;
  margin: 10px 10px;
}

.cats_col_block {
  display: flow-root;
}

.cats_col_block .cat_info:hover {
  background: #f38003;
}

@media screen and (min-width: 1536px)  {
  .cat_single_block {
     width: 32%;
     float: left;
     margin: 10px 10px;
 }
 }
 
 @media screen and (max-width: 1536px) and (min-width: 1024px) {
   .cat_single_block {
     width: 31%;
     height:250px;
     float: left;
     margin: 20px 10px;
    
 }
  .cat_single_block img{ min-height:220px; }
 }
 @media screen and (max-width: 1024px) and (min-width: 599px) {
   .cat_single_block {
     width: 30%;
     height:200px;
     float: left;
     margin: 10px 10px;
 }
  .cat_single_block img{ min-height:137px; }
 .cat_info h3{ font-size:16px; }
 }
 @media screen and (max-width: 599px) {
   .cat_single_block {
     width: 96%;
     min-height: 185px;
     float: left;
     margin: 10px;
     height: auto;
 }
 }
 .logoutBtn {
  float: right;
  position: absolute;
  right: 10px;
  top: -50px;
  background: #f38003;
}

.logoutBtn button {
  color: #fff;
  padding: 15px;
  text-transform: uppercase;
}
.logoutBtn.OrderPAge {
  top: -20px !important;
}
@media only screen and (max-width: 1024px) {
 
}
@media only screen and (max-width: 768px) {

    section.top_banner_section button { display: none !important; }
}


@media only screen and (max-width: 480px) {
  .popupBtn {
    padding: 10px 20px;
    font-size: 14px;
}
}

.widgetBottom {
  background: #0b348c;
  padding: 25px 15px;
}
.AddToCartBottom {
  min-height: 262px;
}

.priceBottom {
  min-height: 162px;
}

.widgetBottom a, .WidgetBtn {
  background: #f38003;
  padding: 10px 23px;
  color: #060606;
  font-weight: bold;
  border-radius: 40px;
}

.widgetBottom a:hover, .WidgetBtn:hover {
  background: #fffcf8;
}
.my_full_new .pro_img {
  width: 40%;
  margin-bottom: 0px;
}

.my_full_new .pro_details {
  width: 60%;
}

.my_full_new .priceBottom {
  min-height: 100%;
}
.dropdown-menu li {
  /* float: left;
  width: 20%;
  margin-right: 3%; */
}
.dropdown-menu li:nth-child(1) a, .dropdown-menu li:nth-child(6) a,
.dropdown-menu li:nth-child(11) a, .dropdown-menu li:nth-child(16) a,
.dropdown-menu li:nth-child(21) a, .dropdown-menu li:nth-child(26) a {
  border-left: none;
}

@media only screen and (max-width: 480px) {
.widgetBottom a, .WidgetBtn  {
  padding: 5px 5px;
  font-size: 13px;
}
.widgetBottom {
  min-height:150px;
}    
}

@media only screen and (max-width: 1280px) {
  .widgetBottom {
      padding: 15px 10px;
  }
  .widgetBottom a {
      padding: 10px 0px;
  }
  .WidgetBtn
  {
      padding: 10px;
  }
  .AddToCartBottom {
      min-height: 230px;
  }
  .AddToCartBottom h4 {
      min-height: 72px;
  }
}
@media only screen and (max-width: 1023px) {
  .productWidget {
      flex-basis: 32% !important;
  }
  .WidgetBtn, .widgetBottom a {
      padding: 10px 15px;
  }
}
@media only screen and (max-width: 1000px) {
  .productWidget {
      flex-basis: 45% !important;
  }
  .my_full_new.productWidget {
      flex-basis: 100% !important;
  }
  .dropdown-menu
  {
    width: auto !important;
  }
}
a#FixLink {
  font-weight: 100;
  background: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
}
.cartCount {
  background: #f38003;
}

@media only screen and (max-width: 767px) {
  .dropdown li a
  {
    min-height: auto;
    border: none;
  }
  .AddToCartBottom h4 {
      min-height: 50px;
  }
  .dropdown li {
    float: left;
    width: 100%;
    min-height: auto;
  }
  .mobileTopMinusTen {
    top: 0px;
    position: relative !important;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {

  a#FixLink {
     height: 80px !important;
     display: block;
 }   
 }

 @media only screen and (min-width: 767px) {
  .onlyWeb {
    display: none !important;
  }
/*     body{ display: none } */
}

.displayNone{ display: none !important; }
.cartPopup{top: 30%;}

.updateProductCart {
  background: #f38003;
  line-height: 2.5;
  padding: 0 10px;
  color: #fff;
  font-weight: bold;
  margin-left: 5px;
}

.updateProductCart:hover {
  background: #a75700;
}
.cartQtyBtn:hover {background: #eee;}